import React from 'react';
import CommonPortfolio from './CommonPortfolio';

const Portfolio = () => {
    return (
        <div>
            <CommonPortfolio items={9} condition={true}/>
        </div>
    );
};

export default Portfolio;